import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="PackBack"/>
                <h2>
                    Deze pagina is niet meer beschikbaar.
                </h2>
                Voor vragen kunt u contact opnemen via info@packback.network.
                <p>Je kunt ons vinden via <a
                    className="App-link"
                    href="https://packback.network/contact-us/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    packback.network
                </a></p>
            </header>
        </div>
    );
}

export default App;
